import * as React from 'react';

export interface OfferingCallbacks {
  onOfferingSelected(offeringId: string, intent): any;
}

export interface OfferingCallbacksContext {
  offeringCallbacks: OfferingCallbacks;
}

export interface OfferingCallbacksProps {
  offeringCallbacks?: OfferingCallbacks;
}

const offeringCallbacksContext = React.createContext<OfferingCallbacksContext>(
  null,
);

export const OfferingCallbacksProvider = offeringCallbacksContext.Provider;

export const OfferingCallbacksConsumer = offeringCallbacksContext.Consumer;

export const withOfferingCallbacksContext = <TProps extends object>(
  WrappedComponent: React.ComponentType<TProps>,
) =>
  class WithOfferingCallbacksContextComponent extends React.Component<
    TProps & OfferingCallbacksProps
  > {
    render() {
      return (
        <OfferingCallbacksConsumer>
          {(context: OfferingCallbacksContext) => {
            const offeringCallbacksProps = {
              offeringCallbacks: context.offeringCallbacks,
            };
            return (
              <WrappedComponent {...this.props} {...offeringCallbacksProps} />
            );
          }}
        </OfferingCallbacksConsumer>
      );
    }
  };
