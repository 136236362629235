import * as React from 'react';
import { Text } from 'wix-ui-tpa/Text';
import s from './MultiOfferingsTitle.st.css';
import {
  RunningEnvironmentProps,
  withRunningEnvironmentContext,
} from '../../context/running-environment-context';
import { AlignmentOptions } from '../../../../Shared/appKeys/SettingsKeys';

interface MultiOfferingsTitleProps extends RunningEnvironmentProps {
  children?;
  alignment?: AlignmentOptions;
}

const MultiOfferingsTitleComponent = ({
  runningEnvironment,
  children,
  alignment,
}: MultiOfferingsTitleProps) => {
  const { isMobile } = runningEnvironment;
  return (
    <div
      {...s('root', {
        alignment,
        disableFontSize: isMobile,
        mobile: isMobile,
      })}
    >
      <Text {...s('title')} typography="largeTitle">
        {children}
      </Text>
    </div>
  );
};

export const MultiOfferingsTitle = withRunningEnvironmentContext(
  MultiOfferingsTitleComponent,
);
