import * as moment from 'moment';

export function setupMomentLocales(locale, translations) {
  try {
    moment.updateLocale(locale, {
      months: translations['moment.months-full'].split('_'),
      monthsShort: translations['moment.months-short'].split('_'),
    });
    moment.locale(locale);
  } catch (e) {
    throw new Error(
      `Could not fetch translations for locale: ${locale} caused by: ${e.message}`,
    );
  }
}
