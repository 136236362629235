import { withSentryErrorBoundary } from '@wix/native-components-infra/dist/es/src/HOC/sentryErrorBoundary/sentryErrorBoundary';
import { SENTRY_BOOKINGS_WIDGET_DSN } from './constants/bookings.const';
import { ComponentNoSentryWithAppSettingData } from './app.exports';

export const component = withSentryErrorBoundary(
  ComponentNoSentryWithAppSettingData,
  {
    dsn: SENTRY_BOOKINGS_WIDGET_DSN,
    config: {
      environment: 'ooi-component',
    },
  },
);
