import * as React from 'react';
import { WidgetApp } from './components/WidgetApp/WidgetApp';
import { withStyles } from '@wix/native-components-infra/dist/es/src/HOC/withStyles/withStyles';
import { overrideStyleProps } from '../Shared/appKeys/overrides';

export const ComponentNoSentry = withStyles(WidgetApp, {
  ltrCssPath: 'widgetComponent.stylable.bundle.css',
  rtlCssPath: 'widgetComponent.rtl.min.css',
});

export const ComponentNoSentryWithAppSettingData = props => {
  return <ComponentNoSentry {...overrideStyleProps(props)} />;
};
