import * as React from 'react';
import OfferingViewImage from './OfferingViewImage/OfferingViewImage';
import OfferingInfo from '../OfferingInfo/OfferingInfo';
import { IOfferingViewModel } from '../../domain/offering-view-model-factory';
import {
  ImageShapeOptions,
  OfferingListLayoutOptions,
} from '../../../Shared/appKeys/SettingsKeys';
import { DEFAULT_IMAGE_CONTAINER } from './OfferingView.const';
import s from './OfferingView.st.css';
import {
  BiLoggerProps,
  withBiLoggerContext,
} from '../context/bi-logger-context';
import { WIDGET_BI_REFERRAL } from '../../adapters/reporting/bi-logger/bi.const';
import { Card } from 'wix-ui-tpa/Card';
import { OverlappingCard } from 'wix-ui-tpa/OverlappingCard';
import { StripCard } from 'wix-ui-tpa/StripCard';
import { OfferingIntent } from '../../platform/navigation/navigation.const';
import {
  OfferingCallbacksProps,
  withOfferingCallbacksContext,
} from '../context/offering-callbacks-context';
import {
  RunningEnvironmentProps,
  withRunningEnvironmentContext,
} from '../context/running-environment-context';

interface OfferingViewProps {
  offeringViewModel: IOfferingViewModel;
}

interface OfferingViewState {
  shouldUpdateImageDimensions: boolean;
}

interface CardProps {
  media;
  info;
  isMobile: boolean;
}

class ClassicOfferingView extends React.Component<
  OfferingViewProps & CardProps
> {
  render() {
    const { media, info, isMobile, offeringViewModel } = this.props;

    return (
      <Card
        data-hook="offering-view"
        media={media}
        info={info}
        {...s('classic', { mobile: isMobile })}
        ratio={offeringViewModel.ratio}
        flippedRatio={offeringViewModel.ratioFlipped}
        invertInfoPosition={offeringViewModel.invertInfoPosition}
        stacked={isMobile}
        mediaAspectRatio={offeringViewModel.image.aspectRatio}
      />
    );
  }
}

class GridOfferingView extends React.Component<OfferingViewProps & CardProps> {
  render() {
    const { media, info, offeringViewModel } = this.props;
    const roundMedia =
      offeringViewModel.image.shape === ImageShapeOptions.ROUND;
    return (
      <Card
        data-hook="offering-view"
        media={media}
        info={info}
        {...s('grid', { roundMedia })}
        roundMedia={roundMedia}
        flippedRatio={offeringViewModel.ratioFlipped}
        stacked={true}
        mediaAspectRatio={offeringViewModel.image.aspectRatio}
      />
    );
  }
}

class OverlappingOfferingView extends React.Component<
  OfferingViewProps & CardProps
> {
  render() {
    const { media, info, offeringViewModel } = this.props;

    return (
      <OverlappingCard
        data-hook="offering-view"
        media={media}
        info={info}
        {...s('overlapping')}
        invertInfoPosition={offeringViewModel.invertInfoPosition}
      />
    );
  }
}

class StripOfferingView extends React.Component<OfferingViewProps & CardProps> {
  render() {
    const { media, info, offeringViewModel } = this.props;

    return (
      <StripCard
        data-hook="offering-view"
        media={media}
        info={info}
        {...s('strip')}
        roundMedia={offeringViewModel.image.shape === ImageShapeOptions.ROUND}
      />
    );
  }
}

class OfferingView extends React.Component<
  OfferingViewProps &
    BiLoggerProps &
    OfferingCallbacksProps &
    RunningEnvironmentProps,
  OfferingViewState
> {
  public static Image;
  private readonly offeringViewImageRef;
  displayName = 'OfferingView';

  constructor(props) {
    super(props);
    this.offeringViewImageRef = React.createRef();
    this.state = { shouldUpdateImageDimensions: false };
    this.imageClickHandler = this.imageClickHandler.bind(this);
  }

  private get isImageVisible() {
    const { offeringViewModel } = this.props;
    return offeringViewModel.image.isVisible;
  }

  private offeringImageDimensions(): { width: number; height: number } {
    if (
      this.offeringViewImageRef.current &&
      this.offeringViewImageRef.current.getClientRects()[0]
    ) {
      const {
        width,
        height,
      } = this.offeringViewImageRef.current.getClientRects()[0];
      return { width, height };
    }
    return DEFAULT_IMAGE_CONTAINER;
  }

  imageClickHandler() {
    this.logViewImageClicked();
    this.onOfferingSelected();
  }

  logViewImageClicked() {
    const { offeringViewModel, biLoggerDriver } = this.props;
    biLoggerDriver.sendWidgetClick(
      offeringViewModel.id,
      offeringViewModel.type,
      WIDGET_BI_REFERRAL.WIDGET_IMAGE,
    );
  }

  onOfferingSelected() {
    const { offeringCallbacks, offeringViewModel } = this.props;
    offeringCallbacks.onOfferingSelected(
      offeringViewModel.id,
      OfferingIntent.SHOW_DETAILS,
    );
  }

  componentDidMount() {
    this.setState({ shouldUpdateImageDimensions: true });
  }

  private getCardComponent() {
    switch (this.props.offeringViewModel.layout) {
      case OfferingListLayoutOptions.OVERLAPPING:
        return OverlappingOfferingView;
      case OfferingListLayoutOptions.STRIP:
        return StripOfferingView;
      case OfferingListLayoutOptions.GRID:
        return GridOfferingView;
      case OfferingListLayoutOptions.CLASSIC:
      default:
        return ClassicOfferingView;
    }
  }

  render() {
    const { offeringViewModel, runningEnvironment } = this.props;
    const { isMobile } = runningEnvironment;
    const CardComponent = this.getCardComponent();

    return (
      <CardComponent
        offeringViewModel={offeringViewModel}
        media={
          this.isImageVisible ? (
            <OfferingViewImage
              forwardedRef={this.offeringViewImageRef}
              onClick={this.imageClickHandler}
              {...this.offeringImageDimensions()}
              imageViewModel={offeringViewModel.image}
              layout={offeringViewModel.layout}
            />
          ) : null
        }
        info={<OfferingInfo offeringViewModel={offeringViewModel} />}
        isMobile={isMobile}
      />
    );
  }
}

export default withRunningEnvironmentContext(
  withOfferingCallbacksContext(withBiLoggerContext(OfferingView)),
);
