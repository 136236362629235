import * as React from 'react';
import s from './OfferingName.st.css';
import { Text } from 'wix-ui-tpa/Text';

import {
  RunningEnvironmentProps,
  withRunningEnvironmentContext,
} from '../../context/running-environment-context';

interface OfferingNameProps extends RunningEnvironmentProps {
  children?;
  onClick?;
}

const OfferingNameComponent = ({
  runningEnvironment,
  onClick,
  children,
  ...rest
}: OfferingNameProps) => {
  const { isMobile } = runningEnvironment;
  return (
    <div
      className={s.root}
      onClick={onClick}
      role="link"
      tabIndex={0}
      aria-label="bookings"
    >
      <Text
        {...s('offeringName', { disableFontSize: isMobile, isMobile }, rest)}
        typography="smallTitle"
        tagName="h3"
      >
        {children}
      </Text>
    </div>
  );
};

export const OfferingName = withRunningEnvironmentContext(
  OfferingNameComponent,
);
